import React, { useState,useRef,useEffect,useContext} from "react";
import { Link, useLocation} from "react-router-dom";
import Login from "../utils/Login";
import SignUp from "../utils/SignUp";
import { UserContext } from "../App";
import CustomDrawerContent from "../utils/CustomeDrawerContent";
function Header({}) {
  const { User,SetUser,handleLogout } = useContext(UserContext);

  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [renderLogin, setRenderLogin] = useState(false);
  const [isSignUp, setisSignUp] = useState(false);
  const location = useLocation();
  const currentPath = location.pathname;
  const toggleDrawer = () => {
    setIsDrawerOpen(!isDrawerOpen);
  };


  return (
    <div className="sticky top-0 z-50  mx-auto  sm:w-full 2xl:w-5/6    justify-center  font-mono relative bg-white ">
    <nav className="flex justify-between items-center  py-2 lg:py-4 px-3 ">
      {/* Header on the left */}
      <div>
        <Link name="home" to="/" className="text-xl font-bold">
        <svg width="200" height="42" viewBox="0 0 233 58" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#clip0_243_1267)">
<path d="M150.059 11.4304C149.225 11.6097 148.219 12.0354 147.9 12.3603C147.422 12.8421 147.041 13.8505 146.931 14.9485C146.796 16.4163 146.796 44.55 146.943 47.026C147.373 54.488 149.213 56.7403 155.494 57.4908C158.733 57.8828 161.763 57.8157 162.965 57.334C163.885 56.9531 164.363 56.0791 164.609 54.2977C164.952 51.6871 164.511 49.11 163.603 48.4154C162.904 47.8777 162.523 47.7657 160.953 47.676C159.248 47.5751 158.941 47.4631 158.622 46.8131C158.426 46.4323 158.414 45.0094 158.352 30.3766C158.291 17.5927 158.254 14.265 158.119 13.9065C157.653 12.618 156.917 11.9234 155.592 11.5312C154.733 11.2847 151.102 11.2175 150.059 11.4304Z" fill="#545AFA"/>
<path d="M133.252 11.6545C131.756 11.9682 131.007 12.5284 130.492 13.7049L130.198 14.3547L130.161 21.7943L130.124 29.2452L129.425 28.6177C127.536 26.9258 125.131 26.1863 121.978 26.3208C119.696 26.4216 117.832 26.9482 115.771 28.0911C114.372 28.8529 113.587 29.4243 112.237 30.668C109.637 33.0432 107.907 35.9452 107.269 39.0489C106.521 42.6677 107.232 46.6675 109.171 49.7712C110.839 52.4377 113.832 55.0706 116.642 56.3703C118.813 57.3675 120.653 57.7149 123.23 57.6252C125.904 57.5357 127.891 56.8635 129.486 55.4966C129.989 55.0595 130.271 54.8915 130.308 54.9812C130.345 55.0706 130.418 55.3172 130.504 55.5415C130.578 55.7655 130.836 56.1575 131.069 56.4152C132.185 57.6029 134.541 58.0286 137.681 57.6029C139.398 57.3675 140.355 56.9195 140.895 56.1015C141.104 55.7766 141.349 55.2723 141.435 54.9812C141.644 54.2977 141.742 17.391 141.558 15.4303C141.337 13.2567 140.638 12.2259 139.043 11.7665C138.172 11.5088 134.295 11.4416 133.252 11.6545ZM126.579 37.2226C127.609 37.6706 128.063 38.0292 128.799 38.9926C129.645 40.0795 129.94 40.9983 129.866 42.3429C129.83 43.2055 129.756 43.4969 129.437 44.1132C128.909 45.1437 127.658 46.3426 126.603 46.8132C125.818 47.1717 125.732 47.1829 124.309 47.1829C122.861 47.1829 122.812 47.1717 121.892 46.7683C120.665 46.2306 119.635 45.3006 119.01 44.1466C117.967 42.2195 118.457 39.9115 120.261 38.242C120.862 37.6817 121.475 37.312 122.297 37.0432C122.849 36.8526 123.205 36.8192 124.37 36.8415C125.659 36.8752 125.855 36.9089 126.579 37.2226Z" fill="#545AFA"/>
<path d="M184.103 11.4753C182.814 12.0803 182.029 13.5929 182.029 15.4079C182.029 16.562 182.25 17.3239 182.802 18.0633C183.784 19.4079 185.378 19.4639 186.617 18.2202C188.322 16.4947 188.347 13.1559 186.666 11.7666C185.808 11.0607 185.133 10.9823 184.103 11.4753Z" fill="#545AFA"/>
<path d="M176.214 12.1027C173.552 12.9991 173.993 18.0634 176.877 19.4975C177.711 19.9121 178.704 19.8784 179.367 19.4191C179.98 18.9933 180.459 18.0522 180.532 17.0998C180.753 14.1643 178.361 11.3857 176.214 12.1027Z" fill="#545AFA"/>
<path d="M190.371 16.4163C189.512 16.6516 188.445 17.5143 187.979 18.3322C186.777 20.461 187.022 22.8027 188.556 23.8559C189.402 24.4385 190.776 24.1136 191.843 23.0828C193.573 21.4134 193.916 18.3658 192.542 16.9989C192.003 16.4611 191.095 16.2146 190.371 16.4163Z" fill="#545AFA"/>
<path d="M171.135 18.2986C169.81 18.8924 169.504 20.7635 170.424 22.545C171.27 24.1808 172.963 25.3908 174.386 25.3908C176.595 25.3908 177.38 23.2508 176.079 20.8195C174.987 18.814 172.644 17.6151 171.135 18.2986Z" fill="#545AFA"/>
<path d="M182.765 24.8418C179.465 25.1891 176.349 26.2647 173.773 27.9341C172.411 28.8194 170.46 30.6231 169.602 31.7882C168.375 33.4691 167.577 35.2617 167.05 37.4914C166.804 38.5108 166.768 38.9928 166.768 41.1888C166.768 43.3848 166.792 43.8554 167.038 44.8525C167.884 48.4154 169.982 51.8102 172.644 53.9502C174.693 55.5859 177.404 56.7625 180.434 57.3228C185.648 58.2862 193.867 57.5917 196.873 55.9222C197.695 55.4628 198.652 54.5665 198.909 54.0062C199.228 53.3005 199.351 52.4488 199.216 51.7765C198.934 50.4097 197.928 48.6508 196.959 47.8328C195.793 46.8468 194.628 46.7797 192.395 47.5639C190.518 48.2137 188.985 48.4825 186.691 48.5499C184.372 48.6171 183.464 48.4714 181.857 47.7542C180.115 46.9814 179.281 46.1745 179.023 45.0317L178.95 44.6731L187.329 44.6397C195.671 44.5948 195.708 44.5948 196.444 44.3482C198.664 43.5865 200.197 41.9394 200.737 39.7322C201.019 38.5557 200.995 36.1917 200.688 34.8582C200.05 32.0908 198.296 29.4802 195.892 27.71C192.751 25.3908 187.807 24.3152 182.765 24.8418ZM187.132 34.4102C188.715 34.8134 189.733 36.0571 189.611 37.4577C189.549 38.1411 189.267 38.5557 188.641 38.8919C188.249 39.1048 187.967 39.1159 183.525 39.1497L178.815 39.1831L178.901 38.7014C179.036 37.9282 179.563 37.0542 180.336 36.3485C181.146 35.6091 182.409 34.8248 183.268 34.5334C184.372 34.1525 185.893 34.1077 187.132 34.4102Z" fill="#545AFA"/>
<path d="M42.8513 25.1556C40.5324 25.5365 38.0175 26.4553 36.1163 27.6205C30.6202 31.0155 27.9458 35.5195 27.9458 41.4017C27.9458 45.2895 29.0499 48.2697 31.5525 51.2055C35.3923 55.6983 41.465 58.1743 47.4271 57.6815C52.8249 57.2443 57.3517 54.97 60.4433 51.1606C61.9889 49.2669 62.8232 47.6983 63.3998 45.6143C64.6754 41.0432 63.8168 36.0237 61.1302 32.3486C58.4313 28.64 53.6469 25.8166 48.8624 25.1108C47.3411 24.8867 44.4093 24.9091 42.8513 25.1556ZM47.6235 35.6426C48.5801 35.878 49.8314 36.4829 50.592 37.088C51.7086 37.9843 52.3586 39.4857 52.3586 41.2C52.3586 43.7435 51.279 45.368 48.7887 46.4995C46.8382 47.396 45.0592 47.4406 43.1946 46.634C41.3914 45.8497 40.3364 44.8415 39.8211 43.4183C39.4651 42.4437 39.4531 40.4492 39.7841 39.4186C40.3484 37.6483 42.0907 36.1917 44.2986 35.6426C45.1576 35.4409 46.7402 35.4297 47.6235 35.6426Z" fill="#545AFA"/>
<path d="M82.2307 25.1556C79.7034 25.5589 76.9799 26.6345 74.821 28.0463C71.0668 30.5112 68.6135 33.8389 67.6933 37.6595C67.2149 39.6986 67.178 42.8695 67.6319 44.8077C68.2946 47.7209 69.8892 50.3763 72.3304 52.6506C74.269 54.4657 76.3912 55.7429 78.9918 56.684C84.2056 58.5777 90.7567 57.8829 95.5288 54.9475C98.3013 53.2332 100.792 50.4323 102.043 47.6312C103.932 43.4183 103.76 38.0629 101.602 34.0966C99.099 29.4692 93.8482 25.9399 88.2418 25.1108C86.7205 24.8867 83.7886 24.9091 82.2307 25.1556ZM87.0028 35.6426C88.6957 36.046 90.401 37.2 91.0513 38.3652C91.9589 40.0123 91.9589 42.4772 91.0513 44.1355C90.2907 45.4912 88.1191 46.8357 86.2055 47.1269C83.9849 47.4743 81.151 46.3875 79.8998 44.7292C78.7098 43.1495 78.5502 40.494 79.5194 38.5335C80.1084 37.3795 81.7523 36.2029 83.5186 35.6875C84.3776 35.4297 86.0458 35.4186 87.0028 35.6426Z" fill="#545AFA"/>
<path d="M216.072 25.1892C214.661 25.3349 212.674 25.7606 211.533 26.164C210.036 26.6906 209.055 27.262 207.975 28.248C206.221 29.8389 205.461 31.7326 205.461 34.5223C205.461 37.8723 206.528 40.6063 208.54 42.3877C209.349 43.116 209.754 43.3623 211.239 44.0906C213.521 45.2 215.447 45.7714 218.673 46.2868C221.372 46.7126 222.39 47.1606 222.39 47.9114C222.39 48.382 221.85 48.8637 221.151 49.0317C220.292 49.2446 218.489 49.3454 217.507 49.2334C216.33 49.0989 214.919 48.6283 213.312 47.8217C211.778 47.0374 210.613 46.6006 209.681 46.4437C208.76 46.2868 207.791 46.4771 207.252 46.9143C206.393 47.5977 204.663 50.2977 204.344 51.4183C204.16 52.0906 204.258 52.9083 204.602 53.5023C204.933 54.1186 205.669 54.5891 207.73 55.5303C212.245 57.5806 216.465 58.1631 222.034 57.4909C224.525 57.1997 227.236 56.4377 228.72 55.6311C230.683 54.5554 232.143 52.5274 232.781 49.9954C233.1 48.7406 233.063 45.7826 232.719 44.5389C232.253 42.7911 231.321 41.2671 230.07 40.2029C228.524 38.8694 224.009 37.3683 220.427 36.9871C219.188 36.8529 217.336 36.4717 216.869 36.2589C216.133 35.9117 215.765 35.4411 215.765 34.8137C215.765 34.2086 216.011 33.9286 216.771 33.6371C217.397 33.3906 219.814 33.2114 220.746 33.3346C221.605 33.4466 224.365 34.1974 225.788 34.6903C227.199 35.1834 227.69 35.1386 228.622 34.4326C229.959 33.402 231.076 31.7437 231.407 30.2649C231.665 29.0883 231.395 28.3488 230.45 27.699C229.051 26.7466 224.905 25.4805 222.206 25.178C220.918 25.0324 217.483 25.0436 216.072 25.1892Z" fill="#545AFA"/>
<path d="M4.69632 0.080576C3.66583 0.215026 2.89294 0.472723 2.30409 0.864869C1.19999 1.59314 0.623403 3.65471 0.942366 5.67146C1.35947 8.27083 1.45761 9.62654 1.53122 13.6825C1.60483 18.4442 1.48215 20.8531 0.770617 29.6486C0.181763 36.8303 0.108157 38.13 0.0222823 41.4689C-0.124931 46.9254 0.463923 51.2614 1.59256 53.1997C2.10781 54.0849 3.42047 55.362 4.47549 56.0006C8.83055 58.656 13.0875 58.2751 16.9273 54.8803C18.5344 53.4574 19.5403 51.956 19.9942 50.2866C20.2396 49.4463 20.2764 49.0206 20.2641 47.0709C20.2641 44.55 20.0924 43.2166 19.1723 38.7237C18.3503 34.7351 18.2277 33.9509 18.2031 32.7297C18.1909 31.7886 18.2277 31.5531 18.4362 31.2171C18.5834 31.004 18.8288 30.8026 18.9883 30.7577C19.16 30.7129 20.0433 30.6791 20.9511 30.668C26.6556 30.6343 30.5446 29.4243 33.3906 26.8138C35.5498 24.8418 36.9851 22.3097 37.721 19.2173C38.028 17.8952 38.028 14.3323 37.721 12.8982C37.4267 11.5425 36.8501 9.83943 36.3348 8.81986C34.2862 4.75271 29.4527 1.41387 23.9567 0.259843C22.9017 0.0357594 22.4601 0.0245551 14.1425 0.00214675C9.35806 -0.00905745 5.10114 0.0245551 4.69632 0.080576Z" fill="#545AFA"/>
<path d="M20.9378 22.6484C21.059 25.0363 20.0043 28.7123 15.7594 28.1228C13.5889 27.3997 13.6214 25.8551 13.1102 24.9372C12.1782 23.2878 9.96625 23.9772 8.35236 23.4329C6.95406 22.9612 6.17376 21.0397 6.50694 19.7127C7.00532 17.7278 9.47289 16.2567 11.6465 15.8025C19.6418 14.132 20.2715 15.576 20.9378 22.6484Z" fill="#F5F5F5"/>
<path d="M11.3106 14.6907C13.0073 14.8757 14.6511 12.9737 14.982 10.4424C15.313 7.91109 14.2058 5.70906 12.5091 5.52403C10.8125 5.33898 9.16876 7.24101 8.83778 9.77232C8.50683 12.3036 9.61396 14.5057 11.3106 14.6907Z" fill="#F5F5F5"/>
<path d="M17.5392 12.8195C19.164 13.3026 21.1827 11.7295 22.048 9.30578C22.9134 6.88212 22.2978 4.52572 20.673 4.04264C19.0482 3.55952 17.0295 5.13269 16.1641 7.55635C15.2988 9.98004 15.9144 12.3364 17.5392 12.8195Z" fill="#F5F5F5"/>
<path d="M22.3585 15.1858C23.6218 16.2367 26.1696 15.5608 28.0492 13.6761C29.9288 11.7914 30.4285 9.41172 29.1652 8.36083C27.902 7.30995 25.3542 7.98586 23.4745 9.87055C21.5949 11.7552 21.0953 14.1349 22.3585 15.1858Z" fill="#F5F5F5"/>
<path d="M22.5293 22.067C23.6204 23.2775 26.2144 22.8946 28.3231 21.2119C30.4317 19.5291 31.2567 17.1837 30.1656 15.9733C29.0746 14.7628 26.4807 15.1457 24.3719 16.8284C22.2632 18.5111 21.4382 20.8565 22.5293 22.067Z" fill="#F5F5F5"/>
</g>
<defs>
<clipPath id="clip0_243_1267">
<rect width="233" height="58" fill="white"/>
</clipPath>
</defs>
</svg>

        </Link>
      </div>

    

      {/* Hamburger icon on the right */}
      <div className="flex items-center">
        {User?
         <button onClick={toggleDrawer} className="text-gray-600 focus:outline-none">
         <svg className="w-6 h-6" fill="none" stroke="black" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
           <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16m-7 6h7" />
         </svg>
       </button>:
        <button className="bg-[#545AFA] text-white rounded-md text-center px-8 py-2  w-[90%] font-bold  text-xl" onClick={(()=>{setRenderLogin(true)})} >Login</button>
        }
      </div>

      {/* Drawer */}
      <div className={`${isDrawerOpen ? "translate-x-0" : "translate-x-full"} fixed top-0 right-0 h-full w-2/3 sm:w-1/3 bg-slate-100 shadow-lg z-50 transition-transform duration-300`}>        
      <div className="flex justify-between items-center p-4 border-b border-gray-200">
          <button onClick={toggleDrawer} className="text-gray-600 focus:outline-none">
            <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
            </svg>
          </button>
        </div>
        {<CustomDrawerContent User={User} toggleDrawer={toggleDrawer}handleLogout={handleLogout} setRenderLogin={setRenderLogin} setisSignUp={setisSignUp}/>}
      </div>
      {/*drawer*/}
    </nav>
    {renderLogin &&  <Login  setRenderLogin={setRenderLogin}/>}
      {isSignUp && <SignUp  setisSignUp={setisSignUp}  />}

    </div>
  );
}
export default Header;


