import React, { useEffect, useState } from "react";
import { model } from "../firebase";
import CreatePetProfile from "./BookingComponent/CreatePetProfile";
function AiTest({setShowAiTest,createPetProfile,setCreatePetProfile,uid}) {
  const [Answer, setAnswer] = useState([]);
  const [petProfiles,SetPetProfiles]=useState([]);
  const [prompt, setPrompt] = useState("");
  const [chatHistory, setChatHistory] = useState([]);
  const [showQuestions, setShowQuestions] = useState(true);
  const [selectedPet,setSelectedPet]=useState();
  const [newPetAdded,setNewPetAdded]=useState(false);
  const questions = [
    `What is the best diet plan for my ${selectedPet ? selectedPet.pettype :"senior dog"}?`,
    `How can I help my  ${selectedPet ? selectedPet.pettype :"cat"} with anxiety?`,
    `What are the essential vaccinations my  ${selectedPet ? selectedPet.pettype :"puppy"} needs?`
  ];
  const [error,setError]=useState("");
const handleSelected =async(id)=>{
  const pet = petProfiles && petProfiles.find(pet => pet.petProfileId === id);
  console.log(pet);
  setSelectedPet(pet);
}
  async function run(question) {
   try{
    const response = await fetch(`https://auth.poodles.in/ManagePetProfile/limitRequest?uid=${uid}`);
    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(errorData?.error || 'Rate limit check failed');
    }

    const { allowed } = await response.json();
    if (!allowed) {
      throw new Error("Hey,You Have Reached The Limit! Let's Talk After 30 Minutes");
    }
    let detailedPrompt;

    // Check if pet details are available
    if (selectedPet) {
      // Construct a prompt that includes the pet details
      detailedPrompt = `
        Pet Details:
        - Name: ${selectedPet.petname}
        - Breed: ${selectedPet.breed}
        - Age: ${selectedPet.age}
        - Weight: ${selectedPet.weight}
        - Gender: ${selectedPet.gender}
        - PetType: ${selectedPet.pettype}
        - Vaccinated: ${selectedPet.vaccinated}
        - Activitylevel: ${selectedPet.activitylevel}
        - Behavioraltraits: ${selectedPet.behavioraltraits}
        - Medicalhistory: ${selectedPet.medicalhistory}
        Question: ${question}
      `;
    } else {
      detailedPrompt = question;
    }

    const chat = model.startChat({ history: chatHistory });

    // Add user prompt to chat history
    const updatedHistory = [
      ...chatHistory,
      {
        role: "user",
        parts: [{ text: question }],
      },
    ];
    setChatHistory(updatedHistory);

    // Add user prompt to answers state immediately
    const newAnswer = {
      prompt: question,
      answer: "", // Temporary placeholder
    };
    setShowQuestions(false);
    setAnswer((prev) => [...prev, newAnswer]);

    const result = await chat.sendMessageStream(detailedPrompt);
    let text = "";
    for await (const chunk of result.stream) {
      const chunkText = chunk.text();
      text += chunkText;
    }

    // Add AI response to chat history
    const finalHistory = [
      ...updatedHistory,
      {
        role: "model",
        parts: [{ text: text }],
      },
    ];
    setChatHistory(finalHistory);

    // Update the last answer in the Answer state with the actual response
    setAnswer((prev) => {
      const updatedAnswers = [...prev];
      updatedAnswers[updatedAnswers.length - 1].answer = text;
      return updatedAnswers;
    });

    setPrompt(""); // Clear the input field after the response has been processed
   }catch(err){
    console.log(err);
    setError(err.message);
   }finally{
    setPrompt(""); // Clear the input field after the response has been processed
   }
  }

  const handleThreeQuestion = async (question) => {
    setPrompt(question);
    await run(question);
  };
const getPetProfiles = async()=>{
  try{
    const response =await fetch(`https://auth.poodles.in/ManagePetProfile/GetAllPetProfiles?uid=${uid}`,{
        method:"get",
        headers:{
            "content-type":"application/json"
        },
       })
       if (!response.ok) {
        const errorData = await response.json(); // Parse the error response as JSON
        const errorMessage = errorData?.error;
        throw new Error(errorMessage)
    }
    const result = await response.json();
    SetPetProfiles(result);
    if (result.length > 0) {
      setSelectedPet(result[0]);
    }
    setNewPetAdded(false);
 }catch(err){
    console.log(err);
 }
}
console.log(selectedPet);
useEffect(()=>{
getPetProfiles()
},[newPetAdded])
  return (
    <div className="bg-slate-200 rounded-md p-2 mt-2 pb-2">
      <div className="w-full flex bg-white rounded-md mt-1 p-2 items-center justify-between gap-2">
        <img
          src="../Images/vet.svg"
          className="w-12 bg-slate-100 justify-center rounded-lg"
          alt="vet"
        ></img>
        <h1 className="font-semibold text-sm text-Font-Primary">
          Meet VetiQ, Your Trusted Virtual Pet Assistant!
        </h1>
        <button className="hover:bg-slate-100 rounded-md" onClick={(()=>{setShowAiTest(false)})}>
          <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" fill="#302E2D" class="bi bi-x" viewBox="0 0 16 16">
         <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708"/>
         </svg>
        </button>
      </div>
      
      <div className="w-full h-96 bg-white rounded-md mt-1 overflow-y-auto p-1">
      {createPetProfile ?<CreatePetProfile uid={uid} setNewPetAdded={setNewPetAdded} setCreatePetProfile={setCreatePetProfile}/>:
        <>
        <div className="flex items-center gap-4 p-2">
        { petProfiles && petProfiles.map((pet,i)=>{
         return (
          <div
          key={i}
          className={`py-1 px-2 rounded-sm cursor-pointer hover:bg-slate-100 ${
            selectedPet.petProfileId === pet.petProfileId
              ? "bg-slate-200 text-black"
              : "bg-white border border-slate-100 text-black"
          }`}
          onClick={() => handleSelected(pet.petProfileId)}
        >
          {pet.petname}
        </div>
         )
         })
        }
        <button className="border border-slate-200 text-black rounded-sm text-center px-2 py-1   font-regular  text-md hover:bg-slate-100" onClick={(()=>{setCreatePetProfile(true)})}>Add A Pet</button>
        </div>
        {showQuestions ? (
          <div className=" mx-auto md:flex">
            {questions.map((question, index) => (
              <p
                key={index}
                onClick={() => handleThreeQuestion(question)}
                className="cursor-pointer font-medium text-sm border-2 border-slate-100 shadow-sm m-4 p-2 rounded-lg"
              >
                {question}
              </p>
            ))}
          </div>
        ) : null}

{Answer.map((answer, index) => (
  <div key={index} className="p-4 mt-4 bg-gray-100 rounded-lg shadow-md">
    <h1 className="font-semibold text-lg text-Font-Primary p-3 bg-white rounded-md">
      {`You: ${answer.prompt}`}
    </h1>
    {answer.answer ? (
      <div className="bg-white p-4 mt-4 rounded-lg shadow-inner">
        <h3 className="font-semibold text-lg text-Font-Primary mb-3">VetiQ:</h3>
        <div >
          {answer.answer.split('\n\n').map((paragraph, i) => (
            <p key={i} className="font-regular text-md text-Font-Secondary mb-4 leading-relaxed">
              {paragraph}
            </p>
          ))}
        </div>
      </div>
    ) : (
      <div className="flex items-center justify-left space-x-2 m-1 animate-bounce">
        <div className="w-2 h-2 bg-slate-500 rounded-full"></div>
        <div className="w-2 h-2 bg-slate-500 rounded-full"></div>
        <div className="w-2 h-2 bg-slate-500 rounded-full"></div>
      </div>
    )}
  </div>
))}</> }
     </div>
       {/* limit message component */}
      {error? <div className="flex w-60 bg-slate-50 rounded-md right-5 p-1 justify-around absolute">
          <p>{error}</p>
        </div>:null}
      <div className="flex w-full bg-white rounded-md mt-2 p-1 justify-around">

        <input
          required
          placeholder="Ask VetiQ About diet, behavior, or health..."
          name="prompt"
          className="w-[90%] rounded-md outline-none p-2"
          value={prompt}
          onChange={(e) => {
            setPrompt(e.target.value);
          }}
        ></input>
        <button
          className="p-2 rounded-lg hover:bg-Auxilary"
          onClick={() => run(prompt)}
          //disabled={prompt.length < 10 ? true : false}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="22"
            height="22"
            fill="#7E57C2"
            className="bi bi-send-fill"
            viewBox="0 0 16 16"
          >
            <path d="M15.964.686a.5.5 0 0 0-.65-.65L.767 5.855H.766l-.452.18a.5.5 0 0 0-.082.887l.41.26.001.002 4.995 3.178 3.178 4.995.002.002.26.41a.5.5 0 0 0 .886-.083zm-1.833 1.89L6.637 10.07l-.215-.338a.5.5 0 0 0-.154-.154l-.338-.215 7.494-7.494 1.178-.471z" />
          </svg>
        </button>
      </div>
      <p className="text-sm font-regular text-center text-Font-Secondary">
        VetiQ can make mistakes,Check important info.
      </p>
    
    </div>
  );
}

export default AiTest;







// import React, { useEffect, useState } from "react";
// import { model } from "../firebase";
// function AiTest({setShowAiTest,setCreatePetProfile,uid}) {
//   const [Answer, setAnswer] = useState([]);
//   const [petProfiles,SetPetProfiles]=useState([]);
//   const [prompt, setPrompt] = useState("");
//   const [chatHistory, setChatHistory] = useState([]);
//   const [showQuestions, setShowQuestions] = useState(true);
//   const [selectedPet,setSelectedPet]=useState({});
//   const questions = [
//     "What is the best diet plan for my senior dog?",
//     "How can I help my cat with anxiety?",
//     "What are the essential vaccinations my puppy needs?"
//   ];
// const handleSelected =async(id)=>{
//   const pet = petProfiles && petProfiles.find(pet => pet.petProfileId === id);
//   console.log(pet);
//   setSelectedPet(pet);
// }
//   async function run(question) {
//     let detailedPrompt;

//     // Check if pet details are available
//     if (selectedPet) {
//       // Construct a prompt that includes the pet details
//       detailedPrompt = `
//         Pet Details:
//         - Name: ${selectedPet.petname}
//         - Breed: ${selectedPet.breed}
//         - Age: ${selectedPet.age}
//         - Weight: ${selectedPet.weight}
//         - Gender: ${selectedPet.gender}
//         - PetType: ${selectedPet.pettype}
//         - Vaccinated: ${selectedPet.vaccinated}
//         - Activitylevel: ${selectedPet.activitylevel}
//         - Behavioraltraits: ${selectedPet.behavioraltraits}
//         - Medicalhistory: ${selectedPet.medicalhistory}
//         Question: ${question}
//       `;
//     } else {
//       detailedPrompt = question;
//     }

//     const chat = model.startChat({ history: chatHistory });

//     // Add user prompt to chat history
//     const updatedHistory = [
//       ...chatHistory,
//       {
//         role: "user",
//         parts: [{ text: question }],
//       },
//     ];
//     setChatHistory(updatedHistory);

//     // Add user prompt to answers state immediately
//     const newAnswer = {
//       prompt: question,
//       answer: "", // Temporary placeholder
//     };
//     setShowQuestions(false);
//     setAnswer((prev) => [...prev, newAnswer]);

//     const result = await chat.sendMessageStream(detailedPrompt);
//     let text = "";
//     for await (const chunk of result.stream) {
//       const chunkText = chunk.text();
//       text += chunkText;
//     }

//     // Add AI response to chat history
//     const finalHistory = [
//       ...updatedHistory,
//       {
//         role: "model",
//         parts: [{ text: text }],
//       },
//     ];
//     setChatHistory(finalHistory);

//     // Update the last answer in the Answer state with the actual response
//     setAnswer((prev) => {
//       const updatedAnswers = [...prev];
//       updatedAnswers[updatedAnswers.length - 1].answer = text;
//       return updatedAnswers;
//     });

//     setPrompt(""); // Clear the input field after the response has been processed
//   }

//   const handleThreeQuestion = async (question) => {
//     setPrompt(question);
//     await run(question);
//   };
// const getPetProfiles = async()=>{
//   try{
//     const response =await fetch(`http://localhost:3000/ManagePetProfile/GetAllPetProfiles?uid=${uid}`,{
//         method:"get",
//         headers:{
//             "content-type":"application/json"
//         },
//        })
//        if (!response.ok) {
//         const errorData = await response.json(); // Parse the error response as JSON
//         const errorMessage = errorData?.error;
//         throw new Error(errorMessage)
//     }
//     const result = await response.json();
//     SetPetProfiles(result);
//  }catch(err){
//     console.log(err);
//  }
// }
// console.log(petProfiles);
// useEffect(()=>{
// getPetProfiles()
// },[])
//   return (
//     <div className="bg-slate-100 rounded-md p-2 mt-2 ">
//       <div className="w-full flex bg-white rounded-md mt-1 p-2 items-center justify-between gap-2">
//         <img
//           src="../Images/vet.svg"
//           className="w-12 bg-slate-100 justify-center rounded-lg"
//           alt="vet"
//         ></img>
//         <h1 className="font-semibold text-sm text-Font-Primary">
//           Meet VetiQ, Your Trusted Virtual Pet Assistant!
//         </h1>
//         <button className="hover:bg-slate-100 rounded-md" onClick={(()=>{setShowAiTest(false)})}>
//           <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" fill="#302E2D" class="bi bi-x" viewBox="0 0 16 16">
//          <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708"/>
//          </svg>
//         </button>
//       </div>
//       <div className="w-full h-96 bg-white rounded-md mt-1 overflow-y-auto p-1">
//         <div className="flex items-center gap-4 p-2">
//         { petProfiles && petProfiles.map((pet,i)=>{
//          return (
//           <div
//           key={i}
//           className={`py-1 px-2 rounded-sm cursor-pointer hover:bg-slate-100 ${
//             selectedPet.petProfileId === pet.petProfileId
//               ? "bg-slate-200 text-black"
//               : "bg-white border border-slate-100 text-black"
//           }`}
//           onClick={() => handleSelected(pet.petProfileId)}
//         >
//           {pet.petname}
//         </div>
//          )
//          })
//         }
//         <button className="border border-slate-200 text-black rounded-sm text-center px-2 py-1   font-regular  text-md hover:bg-slate-100" onClick={(()=>{setCreatePetProfile(true)})}>Add A Pet</button>
//         </div>
//         {showQuestions ? (
//           <div className=" mx-auto md:flex">
//             {questions.map((question, index) => (
//               <p
//                 key={index}
//                 onClick={() => handleThreeQuestion(question)}
//                 className="cursor-pointer font-medium text-sm border-2 border-slate-100 shadow-sm m-4 p-2 rounded-lg"
//               >
//                 {question}
//               </p>
//             ))}
//           </div>
//         ) : null}

//         {Answer.map((answer, index) => (
//           <div key={index} className="p-2 mt-2 bg-gray-100 rounded-md shadow-sm">
//             <h1 className="font-semibold text-md text-Font-Primary p-2 bg-white rounded-md ">
//               {`You: ${answer.prompt}`}
//             </h1>
//             {answer.answer ? (
//               <div className="bg-white p-2 mt-2 rounded-md flex gap-x-2">
//                 {/* <h3 className="font-semibold text-md text-Font-Primary">VetiQ:</h3> */}
//                 <div>
//                   {answer.answer.split('\n\n').map((paragraph, i) => (
//                     <p key={i} className="font-regular text-md text-Font-Secondary mb-2">
//                       {paragraph}
//                     </p>
//                   ))}
//                 </div>
//               </div>
//             ) : (
//               <div className="flex items-center justify-left space-x-2 m-1 animate-bounce">
//                 <div className="w-2 h-2 bg-slate-500 rounded-full"></div>
//                 <div className="w-2 h-2 bg-slate-500 rounded-full"></div>
//                 <div className="w-2 h-2 bg-slate-500 rounded-full"></div>
//               </div>
//             )}
//           </div>
//         ))}
//       </div>
//       <div className="flex w-full bg-white rounded-md mt-1 p-1 justify-around">
//         <input
//           required
//           placeholder="Ask VetiQ About diet, behavior, or health..."
//           name="prompt"
//           className="w-[90%] rounded-md outline-none p-2"
//           value={prompt}
//           onChange={(e) => {
//             setPrompt(e.target.value);
//           }}
//         ></input>
//         <button
//           className="p-2 rounded-lg hover:bg-Auxilary"
//           onClick={() => run(prompt)}
//           disabled={prompt.length < 10 ? true : false}
//         >
//           <svg
//             xmlns="http://www.w3.org/2000/svg"
//             width="22"
//             height="22"
//             fill="#7E57C2"
//             className="bi bi-send-fill"
//             viewBox="0 0 16 16"
//           >
//             <path d="M15.964.686a.5.5 0 0 0-.65-.65L.767 5.855H.766l-.452.18a.5.5 0 0 0-.082.887l.41.26.001.002 4.995 3.178 3.178 4.995.002.002.26.41a.5.5 0 0 0 .886-.083zm-1.833 1.89L6.637 10.07l-.215-.338a.5.5 0 0 0-.154-.154l-.338-.215 7.494-7.494 1.178-.471z" />
//           </svg>
//         </button>
//       </div>
//       <p className="text-sm font-regular text-center text-Font-Secondary">
//         VetiQ can make mistakes,Check important info.
//       </p>
    
//     </div>
//   );
// }

// export default AiTest;



