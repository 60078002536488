import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import FollowUserProfilePost from './FollowUserProfilePost';
import FollowUserBioSection from '../utils/FollowUserBioSection';
function FollowUserProfile({}) {
const {userId} = useParams();
const PostuserId = userId;
const [profileUserData,setProfileUserData]=useState(null)
const [isprofileUserDataFetched,setIsprofileUserDataFetched]=useState(false)
useEffect(()=>{
  const abortController = new AbortController()
  if(PostuserId && PostuserId){
    getSingleUser(abortController)
  }
  return () => {
    abortController.abort();
  };
},[])

const getSingleUser=async(abortController)=>{
  try{
   const response =await fetch('https://auth.poodles.in/graphql',{
    signal:abortController.signal,
    method:"post",
    headers:{
      "Content-Type":"application/json"
    },
    body:JSON.stringify({
      query:`
      query GetSingleUser($userId: String!){
      getSingleUser(userId: $userId){
      fullname
      userId
      bio
      profilepicture{
      fileName
      imageUrl
      }
      followingIds
      followerIds
      username
      postIds
      }
      }
      `,
      variables:{
        userId:PostuserId
      }
    })
   })
   const result = await response.json();
   if(result.errors){
    console.error('GraphQL errors:', result.errors);
    throw new Error(result.error.map(err => err.messsage).join(', '));
   }
   if(result.data.getSingleUser){
     const userData =result.data.getSingleUser;
     setProfileUserData(userData);
     setIsprofileUserDataFetched(true)
     console.log('this is the userData',userData); 
   }
  }catch(err){
    console.log(err);
  }
}

  return (
    
    <div className='mx-auto w-11/12 lg:w-5/12 bg-gray-100 p-4 rounded-xl'>
      <FollowUserBioSection profileUserData={profileUserData} followUserId={PostuserId} isprofileUserDataFetched= {isprofileUserDataFetched}/>
      <div className="mt-8 flex justify-center mx-auto">
      <button className="text-slate-400 border-b-2 mx-1 p-1">
     {profileUserData && profileUserData.postIds &&  profileUserData.postIds.length > 0 ? "Posts" :"No Posts"}
     </button>
    </div>
    <FollowUserProfilePost isprofileUserDataFetched={isprofileUserDataFetched} PostuserId={PostuserId} />
    </div>
)
}

export default FollowUserProfile