import React, { useState } from "react";
import InputField from "../../utils/InputField";
import SnackBar from "../../utils/SnackBar"; 
function CreatePetProfile({setCreatePetProfile,uid,setNewPetAdded}){
    const [isCompleting,setIsCompleting]=useState(false);
    const [isCompleted,setIsCompleted]=useState(false);
    const [isSnackBarVisibale,setIsSnackBarVisibale]=useState(false);
    const [snackMessage,setSnackMessage]=useState("");
    const [details,setDetails]=useState({
     petname:"",
     pettype:"",
     gender:"",
     age:"",
     breed:"",
     vaccinated:"",
     weight:"",
     activitylevel:"",
     behavioraltraits:"",
     medicalhistory:""
    });
const handleChange =async(e)=>{
const inputName = e.target.name;
const newValue = e.target.value;
setDetails((prev)=>{
    return {
        ...prev,
        [inputName]:newValue
    }
})
}
    const labels =[
        {
        title:"Pet Name",
        name:"petname",
        placeholder:"Enter Your Pet Name..",
        type:"text"
        },
         {
        title:"Pet Type",
        name:"pettype",
        placeholder:"Cat,Dog,Bird",
        type:"text"
        },
        {
            title:"Pet Age",
            name:"age",
            placeholder:"Enter Your Pet Age..",
            type:"text"
            },
        {
        title:"Breed",
        name:"breed",
        placeholder:"Enter Your Pet Breed..",
        type:"text"
        },
        {
        title:"Gender",
        name:"gender",
        type:"radio",
        options:[
            "Male",
            "Female",
        ]
        },
        {
        title:"Vaccinated",
        name:"vaccinated",
        type:"radio",
        options:[
         "Yes",
        "No",
        ]
        },
        {
            title:"Weight",
            name:"weight",
            type:"radio",
            options:[
             "0-9Kg",
            "10-23Kg",
            "24-45Kg",
            "46-80Kg"
            ]
            },
            {
            title:"Activity Level",
            name:"activitylevel",
            type:"radio",
            options:[
            "Low",
            "Moderate",
            "High",
            ]
            },
            {
                title:"Behaviour",
                name:"behavioraltraits",
                placeholder:"Enter Your Pet Behaviour..",
                type:"text"
                },
                {        
                    title:"Medical History",
                    name:"medicalhistory",
                    placeholder:"Brief details on past surgeries, injuries, or chronic conditions..",
                    type:"text"
                    },
        
    ]
    const handleSubmit=async(e)=>{
        e.preventDefault();
        const {petname,pettype,gender,age,weight,vaccinated,breed,activitylevel,medicalhistory,behavioraltraits}=details;
     try{
        setIsSnackBarVisibale(true);
        setIsCompleting(true);
        setSnackMessage("Adding New Pet Profile...");
        const response =await fetch(`https://auth.poodles.in/ManagePetProfile/AddNewPetProfile`,{
            method:"Post",
            headers:{
                "content-type":"application/json"
            },
            body:JSON.stringify({uid,petname,pettype,gender,age,weight,vaccinated,breed,activitylevel,medicalhistory,behavioraltraits})
           })
           if (!response.ok) {
            const errorData = await response.json(); // Parse the error response as JSON
            const errorMessage = errorData?.error;
            throw new Error(errorMessage)
        }
        const result = await response.json();
       
        setIsCompleting(false);
        setSnackMessage("Added New Pet Profile...");
        setIsCompleted(true);
        setTimeout(()=>{
        setIsCompleted(false);
          setIsSnackBarVisibale(false);
          setCreatePetProfile(false); 
        },3000)
        setNewPetAdded(true)
     }catch(err){
        console.log(err);
     }
    }
   
    return(
        <div className="  bg-[#F5F5F5] mx-auto  rounded-lg mt-[5%]"> 
        <div className="flex justify-between text-center p-4 ">
        <h1 className='font-bold text-2xl text-Font-Primary '>Create Pet Profile</h1>
        <button className="hover:bg-slate-100 rounded-md"  onClick={(()=>{setCreatePetProfile(false)})}>
          <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" fill="#302E2D" class="bi bi-x" viewBox="0 0 16 16">
         <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708"/>
         </svg>
        </button>
        </div>
        <div className="flex justify-between text-center p-4 ">
        <p>Get started by setting up your pet's profile! Enter basic details about your furry friend to personalize their experience.</p>
        </div>

         <form method="post">
         <div className=" flex flex-wrap justify-between gap-2 mt-4 p-2">
            {
                labels.map((label,i)=>{
                return (
                    <div key={i} className="w-[48%] mb-4">
                     {label.options ?
                    < >
                    <label htmlFor={label.title} className=" font-semibold text-Font-Primary">{label.title}</label>
                    <div className="flex-col w-full ">
                    {label.options.map((option,i)=>{
                        return (
                            <div key={i} className='flex flex-col  w-full  justify-start  border  bg-white  border-Font-Auxilary items-center rounded-lg p-1'>
                            <InputField  onChange={handleChange} type="radio" required  value={option} name={label.name} className='   rounded-md outline-none border border-Font-Secondary'/>
                            <div>
                                <h1>{option}</h1>
                        </div>
                        </div>
                        )
                    })}
                    </div>
                    </>
                     :
                     <div className="">
                     <label htmlFor={label.title} className=" font-semibold text-Font-Primary">{label.title}</label>
                     <InputField required   value={details[label.name]} onChange={handleChange} type={label.type} name={label.name} className='w-full p-2  rounded-md outline-none border border-Font-Secondary' placeholder={label.placeholder}/>
                     </div>
                    }
                    </div> 
                )
                })
            }
             <button className="bg-[#545AFA] text-white font-semibold mx-auto px-8 py-2 rounded-md" onClick={handleSubmit}>Save Profile</button>
            </div>
         </form>
          <SnackBar isSnackBarVisibale={isSnackBarVisibale} isCompleting={isCompleting} isCompleted={isCompleted} snackMessage={snackMessage}/>
        </div>
    )
}
export default CreatePetProfile;
       