import React from "react";
function InputField({ maxlength,readonly,value, name, type, placeholder, onChange, className, id, required, pattern, onBlur, style, checked,autofocus }) {
  return (
    <>
      <input maxlength={maxlength}readonly={readonly}checked={checked} style={style} className={className} id={id} autofocus={autofocus} pattern={pattern} required={required} value={value} name={name} type={type} placeholder={placeholder} onChange={onChange} onBlur={onBlur}></input>
    </>
  );
}

function TextField({
  name,wrap, pattern, rows, cols,required, type, placeholder, className, value, onChange, maxlength,onBlur,style,}) {
  return (
    <textarea wrap={wrap} style={style} pattern={pattern} maxlength={maxlength} required={required} onBlur={onBlur} className={className} value={value} name={name} type={type} placeholder={placeholder} rows={rows} cols={cols} onChange={onChange}
    ></textarea>
  );
}

const FileField = React.forwardRef(({ name, type, accept, onChange, className, id, style }, ref) => (
  <input ref={ref} style={style}name={name}type={type} multiple accept={accept} onChange={onChange}className={className}id={id}  />
));
export default InputField
export { TextField, FileField }

