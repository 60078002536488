import React, { useState, useContext } from "react";
import AiTest from "../Component/AiTest";
import Login from "../utils/Login";
import { UserContext } from "../App";
import { Link } from "react-router-dom";

function MainPage() {
  const { User } = useContext(UserContext);
  const uid = User && User.user.uid;
  const [renderLogin, setRenderLogin] = useState(false);
  const [showAiTest, setShowAiTest] = useState(false);
  const [createPetProfile, setCreatePetProfile] = useState(false);
  const [showPopup, setShowPopup] = useState(false); // State for showing popup
  const [selectedPlan, setSelectedPlan] = useState(null); // State for selected plan
  const [showError,setShowError]=useState(false);
  const handleAisstant = async () => {
    if (User) {
      setShowAiTest(true);
    } else {
      setRenderLogin(true);
    }
  };

  const handlePayment = (plan) => {
    setSelectedPlan(plan); // Set the selected plan
    setShowPopup(true); // Show popup on button click
  };

  const closePopup = () => {
    setShowPopup(false); // Close popup
    setShowError(false);
  };

  return (
    <div className="mx-auto sm:w-full 2xl:w-5/6 bg-slate-100 justify-center gap-8 p-2 font-mono relative">
      <div className="md:flex items-center p-4">
        <div className="p-4 w-full md:w-1/2">
          <h1 className="font-bold text-3xl md:text-5xl">
            Are You Worried About Your Pet?
          </h1>
          <p className="font-medium text-md break-words">
            Need A Meal Plan Or What Fun Activities You Can Do With Your
            Pet? Talk To Your Personal Pet Assistant About Anything...
          </p>
          <button
            onClick={handleAisstant}
            className="bg-slate-900 text-white rounded-md text-center px-4 py-2 mt-4 w-[90%] font-bold text-2xl items-center"
          >
            Ask VetiQ
          </button>
        </div>
        <div className="md:w-1/2">
          <img src="../Images/dogandhuman.svg" className="max-w-[80%]" alt="dog and human" />
        </div>
      </div>

      {/* Plan Section with heading and description */}
      <div className="text-center mt-8">
        <h2 className="text-4xl font-bold mb-4">Choose the Perfect Plan for Your Pet</h2>
        <p className="text-lg text-gray-600 max-w-2xl mx-auto mb-8">
          Whether you need basic care tips, advanced assistance, or exclusive content for your pet's well-being, we have the right plan for you. Select the plan that best suits your needs and give your pet the love and care they deserve!
        </p>
      </div>

      {/* Plan options in a row */}
      <div className="flex justify-around w-full space-x-4 mt-4">
        {/* Plan 1 */}
        <div className="p-4 bg-slate-50 rounded-md w-full max-w-xs">
          <h3 className="font-bold text-xl">Basic Plan - ₹199/month</h3>
          <p className="text-sm text-gray-600">Get access to basic pet care tips and recipes.</p>
          <button
            onClick={() => handlePayment("Basic Plan - ₹199/month")}
            className="mt-2 bg-blue-600 text-white py-2 px-4 rounded-md w-full"
          >
            Buy Now
          </button>
        </div>
        {/* Plan 2 */}
        <div className="p-4 bg-slate-50 rounded-md w-full max-w-xs">
          <h3 className="font-bold text-xl">Premium Plan - ₹499/month</h3>
          <p className="text-sm text-gray-600">Includes advanced tips, recipes, and personal assistant access.</p>
          <button
            onClick={() => handlePayment("Premium Plan - ₹499/month")}
            className="mt-2 bg-blue-600 text-white py-2 px-4 rounded-md w-full"
          >
            Buy Now
          </button>
        </div>
        {/* Plan 3 */}
        <div className="p-4 bg-slate-50 rounded-md w-full max-w-xs">
          <h3 className="font-bold text-xl">Ultimate Plan - ₹999/month</h3>
          <p className="text-sm text-gray-600">Full access to all features, including exclusive content.</p>
          <button
            onClick={() => handlePayment("Ultimate Plan - ₹999/month")}
            className="mt-2 bg-blue-600 text-white py-2 px-4 rounded-md w-full"
          >
            Buy Now
          </button>
        </div>
      </div>

      {/* Popup for the Checkout form */}
      {showPopup && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center">
          <div className="bg-white p-6 rounded-md shadow-lg text-center max-w-md w-full">
            <h2 className="text-xl font-bold mb-4">Complete Your Purchase</h2>
            <form className="flex flex-col space-y-4">
              <input
                type="text"
                placeholder="Name"
                className="p-2 border border-gray-300 rounded-md"
              />
              <input
                type="tel"
                placeholder="Mobile"
                className="p-2 border border-gray-300 rounded-md"
              />
              <input
                type="email"
                placeholder="Email"
                className="p-2 border border-gray-300 rounded-md"
              />
              <input
                type="text"
                placeholder="Address"
                className="p-2 border border-gray-300 rounded-md"
              />
              <p className="font-medium text-lg">Plan: {selectedPlan}</p>
              <button
                type="button"
                onClick={() => setShowError(true)}
                className="bg-blue-600 text-white py-2 rounded-md"
              >
                Pay Now
              </button>
            </form>
            <p className="mt-2 text-xs text-gray-500">
              By proceeding, you agree to our{" "}
              <Link to="/termsofuse" className="text-blue-600 underline">
                Terms and Conditions
              </Link>{" "}
              and{" "}
              <Link to="/privacypolicy" className="text-blue-600 underline">
                Privacy Policy
              </Link>
              .
            </p>
            <button
              onClick={closePopup}
              className="mt-4 bg-red-500 text-white py-2 px-4 rounded-md"
            >
              Close
            </button>
            {showError?<div className=" flex flex-col bg-red-100 mt-2 rounded-md bg-opacity-50  h-20 flex justify-center items-center">
            <h3 className="text-lg font-bold">Error</h3>
            <p className="text-md font-regular">Payment GateWay Is Not Available To Use Currently.We Are Sorry For The Inconvenience</p>
              </div>:null}
          </div>
        </div>
      )}

      <div className="w-[90%] md:w-6/12 top-0 right-0 absolute">
        {showAiTest ? (
          <AiTest
            uid={uid}
            createPetProfile={createPetProfile}
            setShowAiTest={setShowAiTest}
            setCreatePetProfile={setCreatePetProfile}
          />
        ) : null}
      </div>

      {renderLogin && <Login setRenderLogin={setRenderLogin} />}
    </div>
  );
}

export default MainPage;


