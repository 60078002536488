// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getVertexAI,getGenerativeModel } from "firebase/vertexai-preview";
import Instruction from "./systemInstruction"
const firebaseConfig = {
  apiKey: "AIzaSyD_IAmavREuqbPK4-BUHBZg6SrR-AayDvc",
  authDomain: "poodles-8da8a.firebaseapp.com",
  databaseURL: "https://poodles-8da8a-default-rtdb.asia-southeast1.firebasedatabase.app",
  projectId: "poodles-8da8a",
  storageBucket: "poodles-8da8a.appspot.com",
  messagingSenderId: "715538256469",
  appId: "1:715538256469:web:9dcc6ccc5ab6347dca7adc",
  measurementId: "G-DKC7RG1ZD7"
};

const app = initializeApp(firebaseConfig);
// const analytics = getAnalytics(app);
const vertexAI = getVertexAI(app);
const generationConfig = {
    temperature: 0.5,
  };
  const systemInstruction = Instruction.systemInstruction;

const model = getGenerativeModel(vertexAI, { model: "gemini-1.5-flash",systemInstruction },generationConfig);
export {model}