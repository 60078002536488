import React, { useEffect, useState,useRef,useContext } from "react";
import SignUp from "./SignUp.jsx";
import InputField from "./InputField.jsx";
import LoadingSpinner from "./LoadingSpinner.jsx";
import ForgotPassword from "./ForgotPassword.jsx"
import { UserContext } from "../App.js";

function Login({setRenderLogin }) {
  const { User,setUser } = useContext(UserContext);
  const [isSignUp, setisSignUp] = useState(false);
  const [isForgotPass, setForgotPass] = useState(false);
  const [ResponseMessage, SetResponseMesage] = useState("");
  const [isClosePopup, setClosePopup] = useState(false);
  const [isLoggingIn, setIsLoggingIn] = useState(false)
  const [Details, setDetails] = useState({
    email: "",
    password: ""
  });

  function handleDetails(event) {
    const InputName = event.target.name;
    const NewValue = event.target.value;
    setDetails((prevValue) => {
      return {
        ...prevValue,
        [InputName]: NewValue,
      };
    });
  }

  const handleLogInSubmit = async (e) => {
    e.preventDefault()
    const {  email, password } = Details;
    try {
      setIsLoggingIn(true)
      const response = await fetch(`https://auth.poodles.in/LoginUser/logInWithEmailAndPassword`, {
        method: "POST",
        headers: {
          "Content-type": "application/json"
        },
        body: JSON.stringify({ email,password })
        
      })
      if (!response.ok) {
        const errorData = await response.json(); // Parse the error response as JSON
        const errorMessage = errorData?.error;
        throw new Error(errorMessage)
    }
      const result = await response.json();
      setUser(result);
      localStorage.setItem("User", JSON.stringify(result));
      setIsLoggingIn(false)
     setDetails({
    email: "",
    password: "",
     })
     setRenderLogin(false);
    } catch (err) {
      console.log(err)
      SetResponseMesage(err.message)
      setIsLoggingIn(false)
    }
  }
  useEffect(() => {
    document.body.style.overflow = "hidden"
    return () => {
      document.body.style.overflow = "auto"
    }
  }, []);


  function handleSignUp() {
    setisSignUp(true);
    renderComponents();
  }
function handleForgotPassword(){
  setForgotPass(true);
  renderComponents();
}
  function closePopup() {
    setRenderLogin(false);
    setisSignUp(false);
    setClosePopup(!isClosePopup);
    
  }

  function renderComponents() {
    if (isClosePopup) {
      return null;
    }
    if (isSignUp) {
      return <SignUp  setisSignUp={setisSignUp} setRenderLogin={setRenderLogin}/>;
    }
    if(isForgotPass){
      return <ForgotPassword setisSignUp={setisSignUp} setRenderLogin={setRenderLogin}/>
    }
    return (
      <div>

        <div
          className="bg-black/75 backdrop-blur-sm h-screen w-screen absolute inset-0 z-40"
          onClick={closePopup}
        ></div>

        <div className="flex flex-col lg:flex-row w-5/6 md:w-1/2 items-center bg-white absolute top-1/4 left-1/2 -translate-x-1/2 -translate-y-1 z-40 p-6 rounded-lg shadow-lg shadow-white/50">
         
         
         
         
{isLoggingIn ? (
            <div className=" absolute z-50 p-5 top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2">
             <LoadingSpinner className={"h-12 w-12 animate-spin z-50 stroke-black"} />
            </div>
          ) : null}




          {ResponseMessage && (
            <div className=" text-center absolute z-50 text-red-600 font-bold p-5 top-1/3 right-1/6 lg:right-1/2 lg:top-2/3 bg-white rounded-md shadow-md">
              {ResponseMessage}
            </div>
          )}

          <div className="w-1/2">
            <img src="../Images/login.svg" alt="login-welcome" />
          </div>
          <div className="lg:w-1/2 text-center grid gap-6">
            <div>
              <h1 className="text-3xl font-medium">Sign In to Our Community</h1>
            </div>
            <div>
              <form method="POST" className="grid" onSubmit={handleLogInSubmit}>
                <div>
                  <span className="text-base font-extralight">
                    Unlock a World of Pet Love
                  </span>
                </div>
                <div className="grid gap-6 py-4">
                  <div className="relative w-3/4 mx-auto group">
                    <InputField
                      required
                      pattern="[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}"
                      type="email"
                      name="email"
                      placeholder="Email"
                      value={Details.email}
                      onChange={handleDetails}
                      className="border-b border-black outline-none w-full"
                    />
                  </div>

                  <div className="relative w-3/4 mx-auto group">
                    <InputField
                      required
                      pattern="^(?=.*[a-zA-Z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$"
                      type="password"
                      name="password"
                      placeholder="Password"
                      value={Details.password}
                      onChange={handleDetails}
                      className="border-b border-black outline-none w-full"
                    />
                  </div>
                </div>
                <div className="grid gap-2">
                  <span className="text-sm m-1 cursor-pointer"  onClick={handleForgotPassword}>Forget Password ?</span>
                  <button className="w-3/4 mx-auto py-2 px-4 rounded-lg text-white font-extralight bg-stone-800">
                    Sign in
                  </button>
                </div>
                <p className="font-light text-sm mt-2 text-slate-400 break-words ">By signing in,you agree to all terms and conditions of our services and platform.</p>
              </form>
            </div>
            <div>
              <div>
                <span>Not A Part Our Community ?</span>
              </div>
              <div onClick={handleSignUp}>
                <span type="submit" className="underline text-xl mt-1 cursor-pointer">
                  Signup
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
  return <>{renderComponents()}</>;
}

export default Login;
