import React from "react";
import LoadingSpinner from "./LoadingSpinner";
function SnackBar({isCompleted,isCompleting,isSnackBarVisibale,snackMessage}){
    const snackbarAnimationStyle= {
        transition: 'opacity 0.5s ease-in-out, transform 0.5s ease-in-out',
        opacity: isSnackBarVisibale ? 1 : 0,
        transform: isSnackBarVisibale ? 'translateY(0)' : 'translateY(20px)',
        animation: isSnackBarVisibale ? 'easeIn 0.5s ease-in-out' : '',
        visibility: isSnackBarVisibale ? 'visible':'hidden'
      }
return(

   <div id="snackbar" style={snackbarAnimationStyle} className=" flex  gap-1 items-center justify-center min-w-[250px] ml-[-125px] bg-black text-white text-center rounded-md p-2 fixed z-10 left-1/2 bottom-8">
   {isCompleting ? 
   <LoadingSpinner className={"h-6 w-6 animate-spin stroke-white"}/>
  :null}
   {isCompleted ? 
  <svg xmlns="http://www.w3.org/2000/svg" width="14" height="12" viewBox="0 0 21 18" fill="none">
   <path d="M2 8.5C2.43069 9.39052 6.6675 14.9153 7.11909 15.7754C7.73076 16.9404 10.1133 13.2172 10.4999 12.7593C13.3016 9.44108 16.5424 5.84049 18.9999 2" stroke="white" stroke-width="3" stroke-linecap="round" />
 </svg>:null}
 <p className='text-sm'>{snackMessage}</p>
  </div>
)
}
export default SnackBar;