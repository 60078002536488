import React, { useContext, useState, useRef } from "react";
import LoadingSpinner from "../utils/LoadingSpinner";
import { UserContext } from "../App";
import { FileField } from "../utils/InputField";
function HelpCenterPage({ }) {
  const [isSubmitting, setIsSubmitting] = useState(true);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isSnackBarVisibale, setIsSnackBarVisibale] = useState(false);
  const [files, setFiles] = useState([]);
  const fileinputRef = useRef(null);
  const [Details, setDetails] = useState({
    description: ""
  });
  function handleDetails(event) {
    const InputName = event.target.name;
    const NewValue = event.target.value;
    setDetails((prevValue) => {
      return {
        ...prevValue,
        [InputName]: NewValue,
      };
    });
  }
  function handleFileChange(event) {
    const selectedFiles = Array.from(event.target.files);
    setFiles(selectedFiles);
  }
  const handleSubmit = async (event) => {
    event.preventDefault();
    const { description } = Details;
    const formData = new FormData();
    formData.append('description',description)
  
    for(let i=0; i<files.length; i++){
      formData.append(`reqFiles`, files[i]);
     }
    try {
      setIsSubmitting(true);
      setIsSnackBarVisibale(true);
      const response = await fetch(`https://auth.poodles.in/ReportAnProblem/reportAnProblem`, {
        method: "post",
        body: formData,
      })
      if (!response.ok) {
        const errorData = await response.json();
        const errorMessage = errorData?.error;
        throw new Error(errorMessage)
      }
      const result = await response.json();
      setDetails({
        title: "",
        description: ""
      });
      setIsSubmitting(false);
      setIsSubmitted(true);
      setTimeout(() => {
        setIsSubmitted(false);
        setIsSnackBarVisibale(false);
      }, 3000)

      console.log(result);
    } catch (err) {
      console.log(err);
      setIsSubmitting(false);
      setIsSnackBarVisibale(false);
    }
  }
  const snackbarAnimationStyle = {
    transition: 'opacity 0.5s ease-in-out, transform 0.5s ease-in-out',
    opacity: isSnackBarVisibale ? 1 : 0,
    transform: isSnackBarVisibale ? 'translateY(0)' : 'translateY(20px)',
    animation: isSnackBarVisibale ? 'easeIn 0.5s ease-in-out' : '',
    visibility: isSnackBarVisibale ? 'visible' : 'hidden'
  }
  function removeImage(index){
    console.log(index);
    setFiles((prevFiles) => prevFiles.filter((_, i) => i !== index))
    fileinputRef.current.value = '';  
  }
  return (
    <div className="container mx-auto w-11/12  lg:w-5/12  bg-gray-100 p-4 rounded-xl" >
      <h3 className="text-center">Report An Problem</h3>
      <div className="flex  flex-col w-full  lg:p-4 ">

        <div className="flex flex-col gap-4 mt-2 ">
          <form onSubmit={handleSubmit}>
            <div className="relative w-full mx-auto group mt-2">
              <textarea
                required
                name="description"
                rows="5"
                id="description"
                maxLength="1000"
                className=" outline-none text-clip w-full p-2 rounded-xl"
                placeholder="Please include as many details as possible..."
                value={Details.description}
                onChange={handleDetails}
              />
              <p className="text-sm text-slate-500">Plesae share your email as well to get in touch with you..</p>
            </div>
            <div className="submitbutton flex gap-4  justify-between items-center">
              <div className="flex">
                <label htmlFor="select-image" className="cursor-pointer">
                  <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" fill="currentColor" class="bi bi-paperclip" viewBox="0 0 16 16">
                    <path d="M4.5 3a2.5 2.5 0 0 1 5 0v9a1.5 1.5 0 0 1-3 0V5a.5.5 0 0 1 1 0v7a.5.5 0 0 0 1 0V3a1.5 1.5 0 1 0-3 0v9a2.5 2.5 0 0 0 5 0V5a.5.5 0 0 1 1 0v7a3.5 3.5 0 1 1-7 0z" />
                  </svg>
                </label>
                {files && files.map((file, index) => {
                  return (
                    <div key={index} className="flex items-center gap-2">
                      <h3 className="text-sm">{file.name}</h3>
                      <button  type="button" onClick={() => removeImage(index)}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill="currentColor" class="bi bi-x" viewBox="0 0 16 16">
                          <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708" />
                        </svg>
                      </button>

                    </div>
                  )
                })}
                <FileField
                  ref={fileinputRef}
                  required
                  type="file"
                  name="select-image"
                  id="select-image"
                  className="hidden"
                  accept="image/jpeg,image/png,image/jpg" onChange={handleFileChange} />

              </div>

              <div>
                <button type='submit' className="bg-slate-50    p-3 mt-4 rounded-lg text-gray-600 font-bold text-sm">Submit</button>
              </div>
            </div>

          </form>
        </div>


      </div>
      {/*snackbar */}

      <div id="snackbar" style={snackbarAnimationStyle} className=" flex  gap-1 items-center justify-center min-w-[250px] ml-[-125px] bg-black text-white text-center rounded-md p-2 fixed z-10 left-1/2 md:bottom-8">
        {isSubmitting ?
          <><LoadingSpinner className={"h-8 w-8 animate-spin stroke-white"} />
            <p>Sending....</p></> : null}
        {isSubmitted ?
          <><LoadingSpinner className={"h-8 w-8 animate-spin stroke-white"} />
            <p>Message Sent....</p></> : null}
      </div>


    </div>
  );
}


export default HelpCenterPage;
