// export const PrivacyPolicyData = [
//     {
//         heading: "Privacy Policy",
//         details: 
//             `Poodles PetCare ("Company," "we," "us," or "our") is committed to protecting the privacy and security of your personal information. This Privacy Policy outlines the types of personal information we collect, how we use and protect that information, and your rights regarding your personal data.`
        
//     },{
//             heading: "Information We Collect",
//             details: 
//                 "Personal Information: When you register as a user or partner on our platform, we may collect personal information such as your name, email address, phone number, and payment details.Usage Information: We may collect information about how you interact with our platform, including your browsing activity, preferences, and interactions with other users.Device Information: We may collect information about the device you use to access our platform, including your IP address, browser type, and operating system."
            
//         },
//         {
//             heading: "How We Use Your Information",
//             details: 
//                 "To provide and improve our services: We use your personal information to facilitate connections between users and partners, process payments, and enhance the functionality of our platform.To communicate with you: We may use your contact information to send you updates, notifications, and promotional offers related to our services.To ensure security: We may use your information to detect and prevent fraud, unauthorized access, and other security incidents."
            
//         },
//         {
//             heading: "Information Sharing and Disclosure",
//             details: 
//                 "We may share your personal information with trusted third-party service providers who assist us in operating our platform, processing payments, and delivering services.We may disclose your personal information in response to legal requests, court orders, or legal proceedings, or to protect the rights, property, or safety of our users, partners, or others."
            
//         },
//         {
//             heading: "Data Retention",
//             details: 
//                 "We will retain your personal information for as long as necessary to fulfill the purposes outlined in this Privacy Policy, unless a longer retention period is required or permitted by law."
            
//         },
//         {
//             heading: "Your Rights",
//             details: 
//                 "You have the right to access, update, or delete your personal information at any time by contacting us.You have the right to opt out of receiving promotional communications from us by following the instructions provided in our emails.You have the right to request a copy of the personal information we hold about you and to request that we correct any inaccuracies."
            
//         },
//         {
//             heading: "Changes to This Privacy Policy",
//             details: 
//                 "We reserve the right to update or modify this Privacy Policy at any time. Any changes will be posted on this page, and we encourage you to review this Privacy Policy periodically."
            
//         },
       
//     ]

export const PrivacyPolicyData = [
    {
        heading: "Privacy Policy",
        details: 
            `Poodles PetCare ("Company," "we," "us," or "our") is committed to protecting the privacy and security of your personal information. This Privacy Policy outlines the types of personal information we collect, how we use and protect that information, and your rights regarding your personal data.`
    },
    {
        heading: "Information We Collect",
        details: 
            "Personal Information: When you register as a user or partner on our platform, we may collect personal information such as your name, email address, phone number, and payment details. Usage Information: We may collect information about how you interact with our platform, including your browsing activity, preferences, and interactions with other users. Device Information: We may collect information about the device you use to access our platform, including your IP address, browser type, and operating system."
    },
    {
        heading: "How We Use Your Information",
        details: 
            "To provide and improve our services: We use your personal information to facilitate connections between users and partners, process payments, and enhance the functionality of our platform. To communicate with you: We may use your contact information to send you updates, notifications, and promotional offers related to our services. To ensure security: We may use your information to detect and prevent fraud, unauthorized access, and other security incidents."
    },
    {
        heading: "Information Sharing and Disclosure",
        details: 
            "We may share your personal information with trusted third-party service providers who assist us in operating our platform, processing payments, and delivering services. We may disclose your personal information in response to legal requests, court orders, or legal proceedings, or to protect the rights, property, or safety of our users, partners, or others."
    },
    {
        heading: "Data Retention",
        details: 
            "We will retain your personal information for as long as necessary to fulfill the purposes outlined in this Privacy Policy, unless a longer retention period is required or permitted by law."
    },
    {
        heading: "Your Rights",
        details: 
            "You have the right to access, update, or delete your personal information at any time by contacting us. You have the right to opt out of receiving promotional communications from us by following the instructions provided in our emails. You have the right to request a copy of the personal information we hold about you and to request that we correct any inaccuracies."
    },
    {
        heading: "Changes to This Privacy Policy",
        details: 
            "We reserve the right to update or modify this Privacy Policy at any time. Any changes will be posted on this page, and we encourage you to review this Privacy Policy periodically."
    },
    {
        heading: "Payment Policy",
        details: 
            `Your use of the Platform and Services, including transactions, is subject to the following payment conditions:
            
            Charges for Services: You agree to pay all charges associated with availing of the Services on the Platform. All applicable taxes, fees, or other charges will be detailed during the transaction and must be paid in full before confirming any purchase.
            
            Payment Methods: We accept various forms of payments, such as debit cards, credit cards, UPI, and other available payment options listed on the Platform. Payments must be made using an authorized account or method, and by proceeding with the payment, you confirm that you have the authority to use the selected payment method.
            
            Failed Transactions: If any payment attempt fails, Poodles PetCare will not be responsible for any additional charges imposed by your bank or payment service provider. You are advised to resolve the issue directly with them.
            
            
            Transaction Confirmation: Upon successful payment, you will receive a confirmation email or SMS with the transaction details. If there are any discrepancies in the order confirmation, it is your responsibility to report them to customer service immediately.
            
            Pricing Information: All product or service prices listed on the Platform are in INR and are subject to change without prior notice. Any changes to the prices will not affect already confirmed orders.
            `
    },
    {
        heading: "Refund Policy",
        details: 
            `            
           Refunds: Refunds will only be processed for approved cancellation or return requests as detailed under the Refund and Cancellation Policy. Any refunds, once approved, will be credited to the original payment method within 7 working days. Transaction Confirmation: Upon successful payment, you will receive a confirmation email or SMS with the transaction details. If there are any discrepancies in the order confirmation, it is your responsibility to report them to customer service immediately. Pricing Information: All product or service prices listed on the Platform are in INR and are subject to change without prior notice. Any changes to the prices will not affect already confirmed orders.


          Refund and Cancellation Policy: Cancellation: Cancellations are accepted if requested within 7 days of placing the order. However, if the order has already been processed cancellations cannot be accepted.`
    }
];
