import React, { useState } from "react";
import InputField from "./InputField.jsx";
import LoadingSpinner from "./LoadingSpinner.jsx";
function SignUp({ setisSignUp }) {
  const [isClosePopup, setClosePopup] = useState(false);
  const [ResponseMessage, SetResponseMesage] = useState("");
  const [IsLoading, setIsLoading] = useState(false)
  const [Details, setDetails] = useState({
    fullname: "",
    email: "",
    mobile:"",
    password: "",
    confirmpassword: ""

  });

  function handleDetails(e) {
    const InputName = e.target.name;
    const InputValue = e.target.value;
    setDetails((prev) => {
      return {
        ...prev,
        [InputName]: InputValue
      }
    })
  }

  const SignUpNewUser = async () => {
    const { fullname, email, password,mobile } = Details;
    try {
      setIsLoading(true)
      const response = await fetch(`https://auth.poodles.in/CreateUser/createUserWithEmailPassword`, {
        method: "POST",
        headers: {
          "Content-type": "application/json"
        },
        body: JSON.stringify({ email, fullname, password,mobile })
        
      })
      if (!response.ok) {
        const errorData = await response.json(); // Parse the error response as JSON
        const errorMessage = errorData?.error;
        throw new Error(errorMessage)
    }
      const result = await response.json();
      SetResponseMesage(result)
      setIsLoading(false)
     setDetails({
      fullname: "",
    email: "",
    mobile:"",
    password: "",
    confirmpassword: ""
     })
    } catch (err) {
      console.log(err)
      SetResponseMesage(err.message)
      setIsLoading(false)
    }
  }
  const handleSubmit = (e) => {
    e.preventDefault()
    SignUpNewUser().then(() => {
      setTimeout(() => {
        SetResponseMesage("")
        setClosePopup(false);
      }, 4000);
    })

  }


  

  function closePopup() {
    setisSignUp(false);
    setClosePopup(!isClosePopup);
  }

  

    return (
      <div>
        <div
          className="bg-black/75 backdrop-blur-lg h-screen w-screen absolute inset-0 z-40"
          onClick={closePopup}
        ></div>

        <div className="w-5/6 lg:w-1/2 bg-white absolute top-1/4 left-1/2 -translate-x-1/2 -translate-y-1 z-50 p-6 rounded-lg shadow-lg shadow-white/50">
        {IsLoading ? (
            <div className=" absolute z-50 p-5 top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2">
             <LoadingSpinner className={"h-12 w-12 animate-spin z-50 stroke-black"} />
            </div>
          ) : null}

          {ResponseMessage && (
            <div className="relative mx-auto p-4 bg-white rounded-lg shadow-xl transform transition-all ">
              <p className="text-center mt-4 text-sm leading-5 text-gray-500">{ResponseMessage}</p>
            </div>
          )}
          <div>
            <div className="text-center pb-6 flex flex-col gap-4">
              <div>
                <h1 className="text-3xl font-medium">Join Our Community</h1>
              </div>
              <div>
                <span className="text-base font-extralight">
                  Join the Pet-loving Parade – Where Tails Wag and Hearts
                  Connect!
                </span>
              </div>
            </div>
            <div className="flex flex-col lg:flex-row  items-center w-full">
              <div className="w-1/2">
                <img src="../Images/login.svg" alt="login-welcome" />
              </div>
              <div className="w-full lg:w-1/2 text-center grid gap-6">
                <div>
                  <form method="POST"
                    onSubmit={handleSubmit}
                    className="grid">
                    <div className="grid gap-6 py-4">
                      <div className="relative w-3/4 mx-auto group">
                        <InputField
                          required
                          pattern="[A-Za-z\s]+"
                          type="text"
                          name="fullname"
                          placeholder="FullName"
                          value={Details.ngoname}
                          onChange={handleDetails}
                          className="border-b border-black outline-none w-full"
                        />
                      </div>

                      <div className="relative w-3/4 mx-auto group">
                        <InputField
                          required
                          pattern="[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}"
                          type="email"
                          name="email"
                          placeholder="Email"
                          value={Details.email}
                          onChange={handleDetails}
                          className="border-b border-black outline-none w-full"
                        />
                      </div>
                      <div className="relative w-3/4 mx-auto group">
                        <InputField
                          required
                          pattern="[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}"
                          type="text"
                          name="mobile"
                          placeholder="Mobile"
                          value={Details.mobile}
                          onChange={handleDetails}
                          className="border-b border-black outline-none w-full"
                        />
                      </div>
                      <div className="relative w-3/4 mx-auto group">
                        <InputField
                          required
                          pattern="^(?=.*[a-zA-Z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$"
                          type="password"
                          name="password"
                          placeholder="Password"
                          value={Details.password}
                          onChange={handleDetails}
                          className="border-b border-black outline-none w-full"
                        />
                        <p className="font-regular text-sm mt-2 text-slate-400 break-words ">8+ chars, 1 uppercase, 1 lowercase, 1 number, 1 special char required.</p>

                      </div>

                      <div className="relative w-3/4 mx-auto group">
                        <InputField
                          required
                          type="password"
                          name="confirmpassword"
                          placeholder="Confirm Password"
                          value={Details.confirmpassword}
                          onChange={handleDetails}
                          className="border-b border-black outline-none w-full"
                        />
                      </div>

                    </div>
                    <div className="grid gap-2">
                      <button
                        type="submit"
                        className="w-3/4 mx-auto p-2 px-4 rounded-lg text-white font-extralight bg-stone-700">
                        Sign Up
                      </button>
                    </div>
                    <p className="font-light text-sm mt-2 text-slate-400 break-words ">By signing up, you agree to all terms and conditions of our services and platform.</p>
                  </form>
                </div>
                <div>
                 

                  <div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

export default SignUp;
