import React, { useContext,useState} from "react";
import { Helmet } from "react-helmet";
function AboutUs({}) {
  return (
    <div className="container mx-auto w-11/12 h-full lg:w-6/12  xl:w-5/12 text-center bg-gray-100 p-4 rounded-xl" >
      
    <div className="flex  flex-col w-full  p-4   mt-2 items-center">
    <h2 className="font-bold text-5xl text-Logo-color" >About Us</h2>
    <p className="font-medium text-md text-slate-700 mt-4">Building Your Furever Family: A Community for Pawsome Pet Parents! Looking for adoption advice, celebrating rescue success stories, and finding your perfect pup – all in one place? Welcome home! This community is for pawsome pet parents like you. Let's make tails wag & hearts happy! #poodlespetcare🐱 🐶 #AdoptDontShop</p>
    

    <div className="mission lg:flex sm:flex-row bg-white items-center mt-6 rounded-lg">
        <div className="mission-image lg:w-1/2 xl:w-1/2 md:w-full ">
        <img src="../Images/adoptapet.svg" className="w-96 "></img>
        </div>
        <div className="mission-satement lg:w-1/2  text-center  lg:text-left w-full">
            <h3 className="font-bold text-4xl text-Logo-color " >Our Mission</h3>
            <p className="font-medium text-md mt-2 text-slate-700 break-words ">Building Your Furever Family: 
            to fostering a thriving online community where passionate pet parents can connect, share experiences, and build lasting bonds with their furry companions.
            we aim to build a world where every pet finds a loving home and every pet parent feels confident and supported on their journey.🐱 🐶 #AdoptDontShop</p>
            <p className="font-bold text-lg  text-black-500 mt-2"> #Be Part Of Our Community</p>
        </div>
    </div>
     
    
    </div>
    <Helmet>
          <title>Pet Community:online community where passionate pet parents can connect, share experiences, and build lasting bonds with their furry companions.</title>
          <meta
            name="description"
            content="Dog Community For Pet Lovers And Ngo's and Recuers, Find Pet Health Care Tips and Common Pet Problem Tips At One Place.."
          />
          {/* Add other meta tags here if needed */}
        </Helmet>
    </div>
  );
}


export default AboutUs;