import React, { useEffect, useState } from "react";
import InputField from "./InputField.jsx";
import LoadingSpinner from "./LoadingSpinner.jsx";

function ForgotPassword({ setRenderLogin}) {
  const [isError, setError] = useState("");
  const [isClosePopup, setClosePopup] = useState(false);
  const[isLoading,SetIsLoading]=useState(false);
  const [isSent,setIsSent]=useState(false);
  const [isSnackBarVisibale,setIsSnackBarVisibale]=useState(false);
  const [message,setMessage]=useState("");
  const [Details, setDetails] = useState({
    email: "",
  });

  function handleDetails(event) {
    const InputName = event.target.name;
    const NewValue = event.target.value;
    setDetails((prevValue) => {
      return {
        ...prevValue,
        [InputName]: NewValue,
      };
    });
  }

  
  useEffect(() => {
    document.body.style.overflow = "hidden"
    return () => {
      document.body.style.overflow = "auto"
    }
  }, []);


  const HandlePasswordReset = async(e)=>{
try{
  e.preventDefault();
  SetIsLoading(true);
  setIsSnackBarVisibale(true);
    const email = Details.email;
    const response = await fetch(`https://auth.poodles.in/RecoverPassword/passwordresetlink`,{
      method:"post",
      headers:{
        "Content-Type":"application/json"
      },
      body:JSON.stringify({email})
    })
    if(!response.ok){
     const errorData = await response.json();
     const errorMessage = errorData?.error;
     throw new Error(errorMessage);
    }
    const result = await response.json();
    console.log(result);
    SetIsLoading(false)
    setDetails("")
    setIsSent(true);
    setMessage("Sent Link...")
    setTimeout(() => {
      setIsSent(false);
      setIsSnackBarVisibale(false);
    }, 3000);
}catch(error){
    SetIsLoading(false);
    setDetails("");
    setError(error.message);
    console.log(error);
    setTimeout(() => {
      setIsSnackBarVisibale(false);
    }, 3000);
}
}

  function closePopup() {
    setRenderLogin(false);
    setClosePopup(!isClosePopup);
  }
  const snackbarAnimationStyle= {
    transition: 'opacity 0.5s ease-in-out, transform 0.5s ease-in-out',
    opacity: isSnackBarVisibale ? 1 : 0,
    transform: isSnackBarVisibale ? 'translateY(0)' : 'translateY(20px)',
    animation: isSnackBarVisibale ? 'easeIn 0.5s ease-in-out' : '',
    visibility: isSnackBarVisibale ? 'visible':'hidden'
  }
  function renderComponents() {
    if (isClosePopup) {
      return null; // Don't render anything if the popup is closed
    }
    return (
      <div>

        <div
          className="bg-black/75 backdrop-blur-lg h-screen w-screen absolute inset-0 z-40"
          onClick={closePopup}
        ></div>

        <div className="flex flex-col lg:flex-row w-5/6 md:w-1/2 items-center bg-white absolute top-1/4 left-1/2 -translate-x-1/2 -translate-y-1 z-40 p-6 rounded-lg shadow-lg shadow-white/50">

          <div className="w-1/2">
            <img src="../Images/login.svg" alt="login-welcome" />
          </div>
          <div className="lg:w-1/2 text-center grid gap-6">
            <div>
              <h1 className="text-3xl font-medium">Forgot Password?</h1>
            </div>
            <div>
              <form method="POST" className="grid" onSubmit={HandlePasswordReset}>
                <div>
                  <span className="text-base font-extralight">
                    Unlock a World of Pet Love
                  </span>
                </div>
                <div className="grid gap-6 py-4">
                  <div className="relative w-3/4 mx-auto group">
                    <InputField
                      required
                      pattern="[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}"
                      type="email"
                      name="email"
                      placeholder="Email"
                      value={Details.email}
                      onChange={handleDetails}
                      className="border-b border-black outline-none w-full"
                    />
                  </div>

                
                </div>
                <div className="grid gap-2">
                  <button className="w-3/4 mx-auto py-2 px-4 rounded-lg text-white font-extralight bg-stone-800">
                    Get Reset Link
                  </button>
                </div>
              </form>
            </div>
            <div>
            </div>
          </div>
        </div>
          {/*snackbar */}
        
    <div id="snackbar" style={snackbarAnimationStyle} className="flex  gap-1 items-center justify-center min-w-[250px] ml-[-125px] bg-black text-white text-center rounded-md top-1/2 p-2 fixed z-50 left-1/2">
          {isLoading ? 
          <><LoadingSpinner className={"h-6 w-6 animate-spin stroke-white"}/>
          <p className='text-sm'>Sending Link...</p></> :null}
          {isSent ? 
          <><svg xmlns="http://www.w3.org/2000/svg" width="14" height="12" viewBox="0 0 21 18" fill="none">
          <path d="M2 8.5C2.43069 9.39052 6.6675 14.9153 7.11909 15.7754C7.73076 16.9404 10.1133 13.2172 10.4999 12.7593C13.3016 9.44108 16.5424 5.84049 18.9999 2" stroke="white" stroke-width="3" stroke-linecap="round" />
        </svg>
        <p>{message}</p></> :null}
        {isError ? 
          <><svg xmlns="http://www.w3.org/2000/svg" width="14" height="12" viewBox="0 0 21 18" fill="none">
          <path d="M2 8.5C2.43069 9.39052 6.6675 14.9153 7.11909 15.7754C7.73076 16.9404 10.1133 13.2172 10.4999 12.7593C13.3016 9.44108 16.5424 5.84049 18.9999 2" stroke="white" stroke-width="3" stroke-linecap="round" />
        </svg>
        <p>{isError}</p></> :null}
         </div>
      </div>
    );
  }

  return <>{renderComponents()}</>;
}

export default ForgotPassword;
