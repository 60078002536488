import React, { createContext,useEffect,useState } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Header from "./Component/Header";
import MainPage from "./Pages/MainPage";
import LandingPage from "./Pages/LandingPage";
import PostDetails from "./utils/PostDetails";
import ProfilePage from "./Pages/ProfilePage";
import Search from "./Pages/Search";
import HelpCenterPage from "./Pages/HelpCenterPage";
import PrivacyPolicy from "./Pages/PrivacyPolicy";
import TermsOfUse from "./Pages/TermsOfUse";
import AboutUs from "./Pages/AboutUs";
import FollowUserProfile from "./Component/FollowUserProfile";
import VerifyEmailPage from "./Pages/VerifyEmailPage";
import NewPasswordPage from "./Pages/NewPasswordPage";
import PrivateRoute from "./Component/PrivateRoute";
import LoadingSpinner from "./utils/LoadingSpinner";
const UserContext = createContext();
function App() {
const [isAuthenticating, setIsAuthenticating] = useState(true);
const [User,setUser]=useState(false);
 const uid = User && User.user.uid;
 const isAuthenticated = User?.user?.loggedIn;
 const getRefreshToken =async(refreshtoken)=>{
    
  try{
   const response = await fetch(`https://auth.poodles.in/VerifyToken/rereshToken`,{
    method:"Post",
    headers:{
      Accept:"application/json",
      "Content-Type":"application/json",
      'Authorization': `Bearer ${refreshtoken}`
    },
   })
   if(!response.ok){
    const errorData = await response.json(); 
    const errorMessage = errorData?.error;
    throw new Error(errorMessage)
   }
   const result = await response.json();
    localStorage.setItem("User", JSON.stringify(result));
   setUser(result);
   return result;
  }catch(err){
    console.log(`in the getRefreshToken function`,err)
    throw err;
  }
  }

const handleLogout = async()=>{
  try{
 const response = await fetch(`https://auth.poodles.in/LoginUser/logout`,{
  method:"post",
  headers:{
    Accept:"application/json",
    'Content-Type':"application/json"
  },
  body: JSON.stringify({ uid }),
 })
 const result = await response.json();
  localStorage.removeItem('User');
  window.location.replace("https://poodles.in");
 setUser("");
 console.log(result)
  }catch(err){
    console.error(err)
  }
}
useEffect(()=>{
  const getUser = async()=>{
  const jsonValue = await JSON.parse(localStorage.getItem("User"));
  const User = jsonValue;
  if(User){
    const refreshtoken = User && User.RefreshToken;
    const uid = User && User.user.uid;
    try{
      const tokens = await getRefreshToken(refreshtoken);
      setUser(tokens);
    }catch(err){
      if(err.message.includes("User Token Has Expired") || err.message.includes("Refresh Token Did Not Match")){
        console.log(`err after verifying the refreshtoken at the start`,err)
        try{
         await handleLogout();
           }catch(err){
             console.error(err)
           }
      }

    }
  }
  setTimeout(()=>{
  },2000)
  }
  getUser().then(()=>{
    setIsAuthenticating(false); // Mark loading as done after user state is set
  })
  return(()=>{
    clearTimeout();
  })
 
},[])
const userContextValue = {
  User,setUser, isAuthenticated,getRefreshToken,handleLogout

 }
 if (isAuthenticating) {
  return <div className="absolute z-50 p-5 top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2">
   <LoadingSpinner className={"h-10 w-10 animate-spin stroke-black"}/>
  </div>; // You can replace this with a proper loading spinner
}
  return (
  

    <UserContext.Provider value={userContextValue}>
    <Router>
      <Header User={User}  />
      <Routes>
        <Route path="/" element={<MainPage />} />
        {/* <Route path="/feed" element={<LandingPage />} />
        <Route path="/post/:postId" element={<PostDetails />} />
        <Route path="/u/:userId" element={<FollowUserProfile/>} />
        <Route
          path="/search"
          element={
              <Search />
          }
        /> */}
        {/* <Route
          path="/userprofile"
          element={
             <PrivateRoute>
              <ProfilePage />
           </PrivateRoute>
          }
        /> */}
        <Route path="helpcenter" element={<HelpCenterPage />} />
        <Route path="privacypolicy" element={<PrivacyPolicy />} />
        <Route path="termsofuse" element={<TermsOfUse />} />
        <Route path="aboutus" element={<AboutUs />} />
        <Route path="verify-email/:uid/:token" element={<VerifyEmailPage />} />
        <Route path="reset-password/:uid/:token" element={<NewPasswordPage />} />
        <Route path="*" element={<MainPage />} />
      </Routes>
    </Router>
    </UserContext.Provider>
  );
}

export default App;
export { UserContext };