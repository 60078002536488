import React, { useEffect, useState,useContext } from "react";
import InputField from "../utils/InputField";
import { useParams } from "react-router-dom";
import LoadingSpinner from "../utils/LoadingSpinner";
import { UserContext } from "../App.js";
const NewPasswordPage = ({ }) => {
    const { User,setUser } = useContext(UserContext);
    const [isVerifying, setisVerifying] = useState(false);
    const [isVerified, setisVerified] = useState(false);
    const [isSnackBarVisibale, setIsSnackBarVisibale] = useState(false);
    const [error, setError] = useState("");
    const [message,setMessage]=useState("");
    const [Details,setDetails]=useState({
     password:"",
     confirmpassword:""
    });
    const { token, uid } = useParams();

   function handleDetails(event){
   const inputName = event.target.name;
   const value = event.target.value;
   setDetails((prevValue)=>{
    return {
        ...prevValue,
       [inputName]:value
      };
   })
   }
    const snackbarAnimationStyle = {
        transition: 'opacity 0.5s ease-in-out, transform 0.5s ease-in-out',
        opacity: isSnackBarVisibale ? 1 : 0,
        transform: isSnackBarVisibale ? 'translateY(0)' : 'translateY(20px)',
        animation: isSnackBarVisibale ? 'easeIn 0.5s ease-in-out' : '',
        visibility: isSnackBarVisibale ? 'visible' : 'hidden'
    }
    const VerifyEmail =async(abortController)=>{
        try{
            setIsSnackBarVisibale(true)
            setisVerifying(true);
             setMessage("Verifying Email...");
          const response = await fetch(`https://auth.poodles.in/RecoverPassword/EmailVerification?usertoken=${token}&uid=${uid}`,{
            signal:abortController.signal,
            method:"get",
            headers:{
                "Content-Type":"application/json"
            }
          })
          if(!response.ok){
            const errorData = await response.json(); 
        const errorMessage = errorData?.error;
        throw new Error(errorMessage)
          }
          const result = await response.json();
         
          setMessage("Verified...");
         setTimeout(()=>{
            setisVerifying(false);
            setIsSnackBarVisibale(false);
            setisVerified(true);
         },2000)
          console.log(result);
        }catch(err){
            if (err.name !== 'AbortError') {
                console.log(err);
                setError(err.message);
                setisVerifying(false);
                setIsSnackBarVisibale(false);
            }
        }
    }
    useEffect(() => {
        const abortController = new AbortController();
        if(uid && token){
            VerifyEmail(abortController);

        }else{
            console.log("waiting for uid and token")
        }
        return () => {
          abortController.abort();
        };
      }, [uid,token]);

    const Resetpassword =async(e)=>{
        e.preventDefault();
        const {password}=Details;
    try{
        setMessage("Setting New Password...")
        setisVerifying(true);
        setIsSnackBarVisibale(true);
        const response = await fetch(`https://auth.poodles.in/RecoverPassword/UpdateUserPassword`,{
            method:"post",
            headers:{
                "Content-Type":"application/json"
            },
            body:JSON.stringify({uid,password})
        })
       if(!response.ok){
        const errorData = await response.json();
        const errorMessage = errorData?.error;
        throw new Error(errorMessage);
       }
       const result = await response.json();
      
       setMessage("Updated New Password...")
       setTimeout(()=>{
        setisVerifying(false);
        setIsSnackBarVisibale(false);
       },3000)
       setUser(result);
          localStorage.setItem("User", JSON.stringify(result));
          setTimeout(()=>{
            window.location.replace("https://poodles.in");
          },5000)
    }catch(err){
        setMessage("");
        setisVerifying(false);
        setIsSnackBarVisibale(false);
        setError(err.message)
        console.log(err);
    }
    }
    return (
        <>
            {/*snackbar */}

            <div id="snackbar" style={snackbarAnimationStyle} className=" flex  gap-1 items-center justify-center min-w-[250px] ml-[-125px] bg-black text-white text-center rounded-md top-1/2 p-2 fixed z-10 left-1/2">
                {isVerifying ?
                    <><LoadingSpinner className={"h-6 w-6 animate-spin stroke-white"} />
                        <p className='text-sm'>{message}</p></> : null}
            </div>

            {/*verified */}
            {
                isVerified ?

                    <div className="container mx-auto w-11/12 h-full lg:w-5/12  top-1/2 bg-gray-100 p-4 rounded-xl" >

                        <div className=" flex  gap-1 items-center justify-center">
                            <div className="mission lg:flex sm:flex-row bg-white items-center mt-6 rounded-lg p-4">
                                <div className="mission-image lg:w-1/2 xl:w-1/2 md:w-full ">
                                    <img src="../Images/Resetpassword.svg" className="w-96 "></img>
                                </div>
                                <div className="mission-satement lg:w-1/2  text-center  lg:text-left w-full">
                                    <h3 className="font-bold text-4xl text-Logo-color " >Set New Password!</h3>
                                    <p className="font-medium text-md mt-2 text-slate-700 break-words ">Your password must be at least 8 characters and should include a combination of numbers, uppercase and lowercase letters and special characters (!$@%).</p>
                                     {/* form component*/}

                                    <form className="grid" onSubmit={Resetpassword}>

                                        <div className="grid gap-6 py-4">
                                            <div className="relative w-full mx-auto group p-2">
                                                <InputField
                                                    required
                                                    pattern="^(?=.*[a-zA-Z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$"
                                                    type="password"
                                                    name="password"
                                                    placeholder="password"
                                                    value={Details.password}
                                                    onChange={handleDetails}
                                                    className="border-2 rounded-lg border-slate-200 outline-none w-full lg:p-3 p-2"
                                                />
                                            </div>

                                            <div className="relative w-full mx-auto group p-2">
                                                <InputField
                                                    required
                                                    pattern="^(?=.*[a-zA-Z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$"
                                                    type="password"
                                                    name="confirmpassword"
                                                    placeholder="confirmpassword"
                                                    value={Details.confirmpassword}
                                                    onChange={handleDetails}
                                                    className="border-2 rounded-lg border-slate-200 outline-none w-full lg:p-3 p-2"
                                                />
                                            </div>
                                        </div>
                                        <div className="grid gap-2">
                                            <button className="w-3/4 mx-auto py-2 px-4 rounded-lg text-white font-medium bg-Logo-color">
                                               Reset Password
                                            </button>
                                        </div>
                                    </form>

                                </div>
                            </div>
                        </div>

                    </div>
                    : null
            }

 {/* error component*/}
            {
                error ?

                    <div className="container mx-auto w-11/12 h-full lg:w-5/12  top-1/2 bg-gray-100 p-4 rounded-xl" >

                        <div className=" flex  gap-1 items-center justify-center">
                            <div className="mission lg:flex sm:flex-row bg-white items-center mt-6 rounded-lg">
                                <div className="mission-image lg:w-1/2 xl:w-1/2 md:w-full ">
                                    <img src="../Images/error401.svg" className="w-96 "></img>
                                </div>
                                <div className="mission-satement lg:w-1/2  text-center  lg:text-left w-full">
                                    <h3 className="font-bold text-3xl text-red-900 " >Account Verification Failed!!</h3>
                                    <p className="font-medium text-md mt-2 text-slate-700 break-words ">We’re sorry, but we were unable to verify your information.
                                        {error && error}
                                    </p>
                                    <p className="font-medium text-md mt-2 text-slate-700 break-words ">If you continue to experience issues, contact our support team for assistance.</p>
                                </div>
                            </div>
                        </div>

                    </div>
                    : null
            }
        </>
    );
};

export default NewPasswordPage;
