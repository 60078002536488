import React, { useContext,useState} from "react";
import {PrivacyPolicyData} from "../utils/PrivacyPolicyData.js"

function PrivacyPolicy({}) {
    console.log(PrivacyPolicyData)
  return (
    <div className="container mx-auto w-11/12 h-full lg:w-5/12  bg-gray-100 p-4 rounded-xl" >
    
    <div className="flex  flex-col w-full  p-4 border-2 mt-2">
        <h3>Welcome to Poodle Petcare, your one-stop shop for connecting with other pet parents! This platform policy outlines the expectations we have for our community to ensure a safe, inclusive, and pawsome experience for everyone.</h3>
        {
        PrivacyPolicyData.map((item,index)=>{
      return (
        <div key={index}>
    <h3 className="text-lg font-medium text-justify">{item.heading}</h3>
    <p className="text-md font-regular text-justify">{item.details}</p>
    </div>
      )
            })
        }
    
    </div>
        
    </div>
  );
}


export default PrivacyPolicy;
